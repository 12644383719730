import React, { useContext } from "react"

import { RiCloseLine } from "react-icons/ri"
import { useTranslation } from "react-i18next"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import BaseDescription from "../Base/Description"
import BaseTitle from "../Base/Title"
import BaseParchment from "../Base/Parchment"

import BookingWidget from "../BookingWidget"
import { BookModalContext } from "../../context/bookModalContext"

const BookModal = ({ id }) => {
  const { isOpen, setIsOpen, setBookingId } = useContext(BookModalContext)

  const { t } = useTranslation()

  const closeModal = () => {
    setIsOpen(false)
    setBookingId("H57H020493")
    document.querySelector("body").style.overflowY = "scroll"
  }

  return (
    <div
      className={`${
        isOpen ? "" : "hidden"
      } fixed z-1000 top-0 w-screen h-screen bg-modal-wrapper px-12 xs:px-0`}
    >
      <section className="mx-auto w-full max-w-xl h-full flex justify-center items-center xs:items-stretch">
        <BaseParchment light className="p-4 w-full max-h-full">
          <div className="flex justify-between items-center">
            <BaseTitle title={t("utils.book")} className="font-calligraphy" />
            <button onClick={closeModal}>
              <RiCloseLine className="text-action h-8 w-8" />
            </button>
          </div>
          <hr className="border-t border-solid border-gray mt-2"></hr>
          {/* Apply custom max-height to properly display the booking widget */}
          <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 105px)' }}>
            {/* Contact Monique */}
            <div className="mb-12">
              {/* By phone */}
              <div className="mt-4 mb-6 text-center">
                <BaseDescription
                  description={t("modal.callTo")}
                  className="mb-2"
                />
                <OutboundLink
                  href={`tel:${process.env.GATSBY_HREF_MONIQUE_MOBILE_PHONE}`}
                >
                  <BaseTitle
                    title={process.env.GATSBY_DISPLAY_MONIQUE_MOBILE_PHONE}
                    className="text-action"
                  />
                </OutboundLink>
              </div>
              {/* By e-mail */}
              <div className="text-center">
                <BaseDescription
                  description={t("modal.sendTo")}
                  className="mb-2"
                />
                <OutboundLink
                  href={`mailto:${process.env.GATSBY_MONIQUE_EMAIL}`}
                >
                  <BaseTitle
                    title={process.env.GATSBY_MONIQUE_EMAIL}
                    className="text-action"
                  />
                </OutboundLink>
              </div>
            </div>
            <BookingWidget id={id} key={id} />
          </div>
        </BaseParchment>
      </section>
    </div>
  )
}
export default BookModal
