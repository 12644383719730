// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-location-contract-js": () => import("./../../../src/templates/location-contract.js" /* webpackChunkName: "component---src-templates-location-contract-js" */),
  "component---src-templates-our-offers-js": () => import("./../../../src/templates/our-offers.js" /* webpackChunkName: "component---src-templates-our-offers-js" */),
  "component---src-templates-our-region-js": () => import("./../../../src/templates/our-region.js" /* webpackChunkName: "component---src-templates-our-region-js" */),
  "component---src-templates-room-js": () => import("./../../../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */)
}

