import React, { useEffect } from "react"

const BookingWidget = ({ id = "H57H020493" }) => {
  useEffect(() => {
    if (["H57H020493", "H57H020515", "H57H020517", "H57H020518"].includes(id)) {
      const script = document.createElement("script")
      script.async = true
      script.defer = true
      script.src = "https://widget.cloudspire.io/booking/sdk.js"
      document.querySelector(`div.gdf-WidgetBooking#${id}`).appendChild(script)
    } else {
    }
  }, [id])
  return (
    <div>
      <div
        id={id}
        className="gdf-WidgetBooking mt-4"
        data-source="owner"
        data-reference={id}
      ></div>
    </div>
  )
}

export default BookingWidget
