module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chambres d'hôte | La terre du milieu","short_name":"La terre du milieu","start_url":"/","lang":"fr","background_color":"#ffffff","theme_color":"#6D542C","display":"minimal-ui","icon":"src/images/icon.png","localize":[{"start_url":"/en/","lang":"en","name":"Guest rooms | La terre du milieu","short_name":"La terre du milieu","description":"Die Anwendung macht coole Dinge und macht Ihr Leben besser."},{"start_url":"/de/","lang":"de","name":"Guest rooms | La terre du milieu","short_name":"La terre du milieu","description":"Die Anwendung macht coole Dinge und macht Ihr Leben besser."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5a8b9bdf289e865f87bca99aec5a88eb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-E06DVTWCBR"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
